// Tailwind Style
@tailwind base;
@tailwind components;
@tailwind utilities;

// Poppins Fonts
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

html {
  padding: 0 !important;
}

body,
html {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
  background: #e8f1f6;
  overflow-y: overlay !important;
}

:root {
  --primary: #ffffff;
  --secondary: #777777;
}

/* Scrollbar styles */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  opacity: 0.6;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  /*   background: repeating-linear-gradient(
    45deg,
    var(--secondary),
    var(--secondary) 5px,
    var(--primary) 5px,
    var(--primary) 10px
  ); */
  border-radius: 50px;
  border: 2px solid var(--primary);
}

@layer base {
  .btn-primary {
    @apply rounded-md py-3 px-6 text-sm font-bold text-white focus:outline-none focus:ring-2 focus:ring-opacity-75;
  }

  input[type="password"] {
    @apply ltr:pr-10 rtl:pl-10;
  }

  input[disabled],
  textarea[disabled] {
    @apply bg-black/5 border-black/5;
  }

  button[disabled] {
    @apply opacity-50;
  }

  .disabledSelect {
    .customSelect__control {
      @apply bg-black/5 pointer-events-none;
      cursor: default !important;
      border-color: hsla(0, 0%, 0%, 0.1) !important;
    }
  }

  .btn[disabled] {
    opacity: 0.35 !important;
  }

  // Checkbox
  .customCheckbox {
    @apply flex items-center;

    input {
      @apply rounded-sm border border-primary-light bg-center;
      width: 14px;
      height: 14px;

      &:checked {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2704 0H1.5338C0.690209 0 0 0.690209 0 1.5338V12.2704C0 13.114 0.690209 13.8042 1.5338 13.8042H12.2704C13.114 13.8042 13.8042 13.114 13.8042 12.2704V1.5338C13.8042 0.690209 13.114 0 12.2704 0Z' fill='%231D337C'/%3e%3cpath d='M10.7243 4.27036C11.0919 4.63085 11.0919 5.21531 10.7243 5.57579L6.48904 9.72964C6.12149 10.0901 5.52557 10.0901 5.15802 9.72964L3.27566 7.88348C2.90811 7.523 2.90811 6.93854 3.27566 6.57806C3.64322 6.21757 4.23914 6.21757 4.60669 6.57806L5.82353 7.7715L9.39331 4.27036C9.76086 3.90988 10.3568 3.90988 10.7243 4.27036Z' fill='white'/%3e%3c/svg%3e ");
        @apply border-none;

        .rtl &,
        [dir="rtl"] & {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2704 0H1.5338C0.690209 0 0 0.690209 0 1.5338V12.2704C0 13.114 0.690209 13.8042 1.5338 13.8042H12.2704C13.114 13.8042 13.8042 13.114 13.8042 12.2704V1.5338C13.8042 0.690209 13.114 0 12.2704 0Z' fill='%231D337C'/%3e%3cpath d='M10.7243 4.27036C11.0919 4.63085 11.0919 5.21531 10.7243 5.57579L6.48904 9.72964C6.12149 10.0901 5.52557 10.0901 5.15802 9.72964L3.27566 7.88348C2.90811 7.523 2.90811 6.93854 3.27566 6.57806C3.64322 6.21757 4.23914 6.21757 4.60669 6.57806L5.82353 7.7715L9.39331 4.27036C9.76086 3.90988 10.3568 3.90988 10.7243 4.27036Z' fill='white'/%3e%3c/svg%3e ");
        }
      }

      @media (max-width: 1024px) {
        width: 24px;
        height: 24px;

        &:checked {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");

          .rtl &,
          [dir="rtl"] & {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");
          }
        }
      }
    }

    label {
      @apply text-black ltr:pl-2 rtl:pr-2;
      font-size: 13px;
    }
  }

  // DatePicker
  .datePicker {
    .customDatePicker {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.5 8.33329H1.5M12.3333 1.66663V4.99996M5.66667 1.66663V4.99996M5.5 18.3333H12.5C13.9001 18.3333 14.6002 18.3333 15.135 18.0608C15.6054 17.8211 15.9878 17.4387 16.2275 16.9683C16.5 16.4335 16.5 15.7334 16.5 14.3333V7.33329C16.5 5.93316 16.5 5.2331 16.2275 4.69832C15.9878 4.22791 15.6054 3.84546 15.135 3.60578C14.6002 3.33329 13.9001 3.33329 12.5 3.33329H5.5C4.09987 3.33329 3.3998 3.33329 2.86502 3.60578C2.39462 3.84546 2.01217 4.22791 1.77248 4.69832C1.5 5.2331 1.5 5.93316 1.5 7.33329V14.3333C1.5 15.7334 1.5 16.4335 1.77248 16.9683C2.01217 17.4387 2.39462 17.8211 2.86502 18.0608C3.3998 18.3333 4.09987 18.3333 5.5 18.3333Z' stroke='%231D337C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
      background-repeat: no-repeat;
      background-position: top 12px right 18px;

      .rtl &,
      [dir="rtl"] & {
        background-position: top 12px left 18px;
      }
    }

    .react-datepicker__tab-loop {
      @apply flex justify-center w-full;
    }

    .react-datepicker-popper {
      // transform: none !important;
      // inset: auto !important;
    }

    .react-datepicker {
      &__triangle {
        @apply flex justify-center;
        width: 100% !important;

        &:after,
        &:before {
          left: auto !important;
        }
      }
    }
  }
}

.addButton {
  @media (max-width: 640px) {
    span {
      @apply hidden;
      padding: 0 11px !important;
    }
  }
}

// Table
.customTable {
  @apply w-full;

  table {
    @apply w-full;

    thead {
      @apply ltr:text-left rtl:text-right text-primary-text text-xs font-semibold bg-primary-101;

      th {
        @apply p-4 py-3 capitalize text-xs font-semibold leading-5;
        max-width: 100px;
        height: 54px;

        &:first-child {
          width: 200px;
          min-width: 200px;
        }

        &:last-child {
          width: 150px;
          min-width: 150px;
        }

        [dir="rtl"] & {
          direction: ltr;
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        td {
          @apply border-b border-gray-900 text-black text-sm font-normal truncate px-4;
          max-width: 100px;
          padding-top: 18px;
          padding-bottom: 18px;

          button + button {
            @apply ltr:ml-2 rtl:mr-2;
          }

          &:last-child {
            overflow: unset;
          }

          [dir="rtl"] & {
            direction: ltr;
            text-align: right;
          }
        }
      }
    }
  }
}

.breadCrumb {
  li {
    &:last-child {
      a {
        @apply pointer-events-none text-primary-medium;

        div {
          span {
            @apply hidden;
          }
        }
      }
    }
  }
}

.react-datepicker {
  &__header__dropdown {
    margin: 5px 0;
    @apply flex justify-center items-center px-2;
  }

  &__month-dropdown-container,
  &__year-dropdown-container {
    width: 100%;
    margin: 0 !important;

    select {
      @apply bg-white outline-none w-full rounded text-xs;
      padding: 5px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='20' height='20' fill='white'/%3e%3cpath d='M7.40741 8.14815L11.1111 11.8519L14.8148 8.14815' stroke='%231D337C' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
      background-repeat: no-repeat;
      background-position: right 2px center;

      .rtl &,
      [dir="rtl"] & {
        background-position: left 2px center;
      }

      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
    }
  }

  &__year-dropdown-container {
    margin-left: 10px !important;

    .rtl &,
    [dir="rtl"] & {
      margin-right: 10px !important;
    }
  }

  &__day--selected {
    background: #1d337c !important;
    color: white !important;
  }

  &__navigation-icon {
    &:before {
      border-color: #1d337c !important;
    }
  }
}

select::-ms-expand {
  display: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.uploadingLoader {
  @apply w-4 h-4 rounded-full relative bg-white border border-primary-medium;
  transform: rotate(45deg);

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 6px solid #1d337c;
    animation: prixClipFix 2s infinite linear;
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}

.breadCrumbs {
  @apply flex items-center;
  overflow: unset;

  a {
    @apply text-black font-medium text-xs hover:text-secondary-medium;
  }

  & + .breadCrumbs {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.425949 6.84786C0.136754 6.55132 0.142712 6.07648 0.439256 5.78728L2.61055 3.6698L0.493067 1.49851C0.203871 1.20197 0.209829 0.727132 0.506374 0.437937C0.802919 0.148741 1.27776 0.154699 1.56695 0.451244L4.20807 3.15948C4.49726 3.45602 4.4913 3.93086 4.19476 4.22005L1.48653 6.86117C1.18998 7.15036 0.715145 7.14441 0.425949 6.84786Z' fill='%235D6877'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: left center;
    @apply ltr:ml-3 ltr:pl-3 rtl:mr-3 rtl:pr-3;

    .rtl &,
    [dir="rtl"] & {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.57404 6.84785C4.86323 6.5513 4.85728 6.07647 4.56073 5.78727L2.38944 3.66979L4.50692 1.4985C4.79612 1.20195 4.79016 0.727117 4.49361 0.437921C4.19707 0.148726 3.72223 0.154684 3.43304 0.451228L0.791923 3.15946C0.502727 3.45601 0.508685 3.93084 0.805229 4.22004L3.51346 6.86115C3.81001 7.15035 4.28484 7.14439 4.57404 6.84785Z' fill='%235D6877'/%3e%3c/svg%3e ");
      background-position: right center;
    }
  }

  &:last-child {
    a {
      @apply pointer-events-none text-gray-200 font-normal;

      span {
        width: calc(100vw - 900px);

        @media (max-width: 1024px) {
          width: calc(100vw - 250px);
        }
      }
    }
  }
}

.btnPrimary {
  svg + span {
    padding-left: 10px;

    .rtl &,
    [dir="rtl"] & {
      padding-left: 0px;
      padding-right: 10px;
    }
  }
}

.openMenu {
  .arrow {
    transform: rotate(-180deg) !important;
  }
}

.react-pdf__Document {
  @apply my-auto;
  margin: 20px;

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
}

// Tab style
.tabContainer {
  .tab {
    @apply text-gray-108 font-normal text-sm px-5 relative;

    &.active {
      @apply font-semibold;

      &:after {
        content: "";
        @apply absolute w-full h-1 bg-secondary-medium;
        bottom: -2px;
      }
    }
  }
}

.react-confirm-alert-body-element .mainContainer {
  overflow: hidden !important;
}

.iconDropdown {
  label {
    width: 38px;
    height: 38px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @apply bg-white text-black shadow-100 hover:bg-secondary-medium hover:text-white;

    span {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    span + div {
      @apply hidden;
    }
  }

  ul {
    width: 185px;
    top: 56px !important;
    right: -4px !important;
    border-radius: 6px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;

    .rtl &,
    [dir="rtl"] & {
      left: -4px !important;
      right: auto !important;
    }
  }

  &.iconDropdown-collaborations {
    ul {
      width: 210px;
    }
  }
}

.selectedCard {
  .downArrow {
    @apply opacity-0;
  }

  .pending,
  .active,
  .active-institutes {
    .downArrow {
      @apply opacity-100;
    }
  }
}

table {
  tbody {
    tr {
      td {
        .btn-link {
          svg {
            padding-left: 3px;
          }

          .delete {
            @apply text-red-600;

            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

.current-status {
  @apply border-2 border-secondary-medium;
}

.radioButton {
  @apply p-0 m-0 space-x-2 rtl:space-x-reverse;

  .radio {
    @apply border-primary-light;
    width: 18px;
    height: 18px;
    background: white;
    margin-top: 4px;
  }

  input[disabled] + span {
    @apply opacity-60;
  }

  span {
    @apply leading-normal -mb-1;
  }

  .radio:checked,
  .radio[aria-checked="true"] {
    @apply border border-primary-medium bg-primary-medium;
    animation: radiomark var(--animation-input, 0.2s) ease-in-out;

    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0), 0 0 0 3px white inset !important;
  }

  &.radioError {
    .radio {
      @apply border-red-200;
    }
  }
}

.uploadFileItem {
  [dir="rtl"] & {
    direction: ltr;
    text-align: right;
  }
}

// .search {
//   input {
//     [dir="rtl"] & {
//       direction: ltr;
//       text-align: left;
//     }
//   }
// }
input[type="file"] {
  color: transparent;
}

// .defaultInput,
.side-bar-mobile {
  [dir="rtl"] & {
    direction: ltr;
    text-align: left;
  }
}

.icon__Dropdown {
  label {
    width: auto;
    height: 10px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-primary-medium bg-transparent hover:bg-transparent;

    span {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    span + div {
      @apply hidden;
    }
  }

  ul {
    width: 185px;
    top: 36px !important;
    right: -4px !important;
    border-radius: 6px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    z-index: 999 !important;

    .rtl &,
    [dir="rtl"] & {
      left: -4px !important;
      right: auto !important;
    }
  }
}

.overflowUnset {
  overflow: unset !important;
}

.filterDropdown {
  .btn {
    width: 38px;
    height: 38px;
    min-height: 38px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c8cee1 !important;
    position: relative;

    // &::after {
    //   content: '';
    //   @apply w-2.5 h-2.5 absolute top-0 right-0 rounded-full bg-primary-medium;
    // }

    &:hover {
      border: none !important;
    }

    @apply bg-transparent text-black shadow-100 hover:bg-secondary-medium hover:text-white;

    svg {
      width: 20px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    span + div {
      @apply hidden;
    }
  }

  ul {
    width: 170px;
    top: 46px !important;
    right: 0px !important;
    border-radius: 6px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;

    .rtl &,
    [dir="rtl"] & {
      left: 0px !important;
      right: auto !important;
    }

    label {
      &:hover {
        background-color: transparent !important;
      }
    }

    div {
      background: transparent !important;
      padding-left: 0 !important;

      label {
        padding-left: 0 !important;
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }

  .filter-radio-buttons {
    &:active {
      background-color: transparent !important;
    }

    input {
      width: 14px;
      height: 14px;
      margin-top: 2px !important;
    }

    .radio:checked {
      background-color: #1d337c !important;
      box-shadow: 0 0 0 3px white, 0 0 0 2px white inset !important;
    }

    span {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-left: 0 !important;
    }
  }
}

.modal-open {
  overflow: hidden !important;
}

// Editor Style
.rdw-editor-toolbar {
  justify-content: flex-end !important;
  padding: 14px 20px 8px !important;

  .rdw-option-wrapper {
    border: none !important;
  }

  .rdw-option-active {
    border: 1px solid #f1f1f1;
    box-shadow: 1px 1px 0px #bfbdbd inset;
  }

  [title="Indent"],
  [title="Outdent"] {
    @apply hidden;
  }
}

.rdw-editor-main {
  padding: 0px 12px 20px !important;

  [aria-label="rdw-editor"] {
    min-height: 300px;
  }
}

.iconButton {
  span {
    display: none;
  }
}

.chipPrimary {
  @apply bg-primary-medium text-white;

  span {
    @apply text-white;
  }

  button {
    @apply text-white;

    SVG {
      path {
        @apply stroke-white;
      }
    }
  }
}

.dropdown-content {
  button[disabled] {
    color: black !important;
    opacity: 0.45 !important;
  }
}

.invite-drop-down {
  ul {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.pdf-pagination-buttons {
  .btn-disabled {
    opacity: 0.8 !important;
  }
}

.scholar-profile-upload {
  .uploadContainer,
  .upload-file-section {
    width: 100% !important;
    height: 100% !important;
  }
}

.no-image-upload-section {
  .upload-file-section {
    width: 100% !important;
    height: 100% !important;
  }
}

.buttons-container {
  .delete-image-button {
    &:hover {
      border: 1px solid #b80505 !important;

      span {
        color: #b80505 !important;
      }
    }
  }
}

.shadow-alertDropdown {
  .tabContainer {
    @apply ml-4 mr-4;
    width: calc(100% - 16px);

    .tab {
      color: #03041c;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      line-height: normal;

      @media screen and (max-width: 1025px) {
        @apply px-0 mr-6;
      }
    }

    div:first-child {
      .tab {
        @apply ltr:mr-1 rtl:ml-1;
      }
    }
  }
}

.invite-radio-buttons {
  span {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

  input {
    width: 14px !important;
    height: 14px !important;
  }

  .radio:checked {
    background-color: #1d337c !important;
    box-shadow: 0 0 0 3px white, 0 0 0 2px white inset !important;
  }
}

.dashboard-cards {
  svg {
    width: 100%;
    height: 100%;
  }
}

.share-input-section {
  .share-input {
    &::placeholder {
      color: #5d6877 !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
    }
  }
}

.Word-break {
  word-break: break-word;
}

.funding-text {
  span {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 1025px) {
  .mobile-sidebar-open {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 1025px) {
  .side-bar-mobile {
    margin-right: unset !important;
    margin-left: unset !important;
  }
}

@media screen and (max-width: 1025px) {
  .higher-education-checkbox {
    @apply rtl:right-4;

    input {
      @apply rtl:order-1;
    }

    label {
      @apply rtl:pr-0 rtl:pl-2;
    }
  }
}

@media (max-width: 1025px) {
  .miniSidebar .sidebar .subMenu li {
    @apply ltr:pl-12 rtl:pr-12;
  }
}

@media (max-width: 1025px) {
  .miniSidebar .sidebar .navItem {
    justify-content: flex-start !important;
  }

  .miniSidebar .sidebar .text {
    @apply rtl:mr-0;
  }
}

.customSelect {
  &__single-value {
    @apply font-medium text-base;
  }

  &__control--is-disabled {
    color: #7890a1 !important;

    .customSelect__single-value {
      color: #7890a1 !important;
    }

    .multiSelect__placeholder {
      color: #7890a1 !important;
    }
  }
}

.multiSelect__control.multiSelect__control--is-disabled {
  .multiSelect__placeholder {
    color: #7890a1 !important;
  }
}

input[disabled],
textarea[disabled] {
  color: #7890a1 !important;

  &::placeholder {
    color: #7890a1 !important;
  }
}

button.btn[disabled] {
  opacity: 0.7 !important;
  color: #7890a1 !important;
}

.close-replies-button {
  svg {
    width: 16px;

    height: 16px;
  }
}

.funding-organization-signup-form {
  .customSelect {
    &__menu {
      z-index: 15 !important;
    }
  }
}

.highlight-input {
  &:first-line {
    background-color: #1d337c;
    color: #ffffff;
  }
}

.preview-descriptor {
  div {
    margin-bottom: 2px;
  }
}

.invite-notification {
  .infinite-scroll-component {
    overflow-x: hidden !important;
  }
}

.msgFilterTab {
  button {
    @apply text-sm text-gray-108 border-b-4 border-white pb-2.5;
    padding-left: 18px;
    padding-right: 18px;

    &.active {
      @apply border-secondary-medium font-semibold;
    }
  }

  & + .msgFilterTab {
    @apply ml-2.5;
  }
}

.researchScholarMsg {
  .msgListing {
    max-height: calc(100vh - 2px);
  }
}

.msgTab + div {
  .msgListing {
    max-height: calc(100vh - 110px);
    margin-top: 20px !important;
    padding-top: 0 !important;
  }
}

@media (max-width: 1024px) {
  .msgTab + div {
    .msgListing {
      max-height: calc(100vh - 178px);
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.sidebar {
  .menu {
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      opacity: 0 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.308);
      border-radius: 50px;
      border: none;
    }
  }
}

.tippy-box {
  @apply relative text-white rounded text-sm whitespace-normal outline-0 ml-1;
  background-color: #333;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
}

.toggle {
  @apply w-10 border-primary-light;
  height: 22px;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px hsl(0deg 0% 100%) inset,
    0 0 0 2px hsl(0deg 0% 100%) inset, var(--togglehandleborder);
  --handleoffset: 18px;

  &.toggle-primary:checked,
  &.toggle-primary[checked="true"],
  &.toggle-primary[aria-checked="true"] {
    @apply border-primary-light bg-secondary-medium;
  }
}

.roles-list div:nth-child(even) {
  background: #f3f5f6;
}

.sessionThree {
  span {
    @apply truncate;
  }
}

.dangerously-set-html {
  ol,
  ul {
    list-style: disc;
    @apply ltr:pl-4 rtl:pr-4;

    &::marker {
      position: relative;
      margin-left: 0;

      [dir="rtl"] & {
        margin-right: 0;
      }
    }
  }

  ol {
    ul {
      list-style: none;
    }

    li {
      counter-reset: ol0;
      position: relative;

      &::before {
        content: "" counter(list-item) ". ";
        position: absolute;
        left: -16px;

        [dir="rtl"] & {
          right: -16px;
          left: 0;
        }
      }

      &::marker {
        opacity: 0;
        color: transparent;
      }

      // &:before {
      //   content: counter(ol0) ". ";
      //   counter-increment: ol0;
      //   position: absolute;
      //   left: -16px;
      // }
    }
  }

  ul {
    ul {
      list-style: circle;

      ul {
        list-style: square;
      }
    }
  }

  span,
  div {
    background: transparent !important;
  }
}

@media screen and (max-width: 1025px) {
  .smaller-devices-section {
    .smaller-devices-container {
      background-color: white;
      margin-left: -15px;
      padding-left: 15px;
      padding-bottom: 16px !important;

      h3 {
        display: none;
      }

      .breadCrumbs:last-child a span {
        width: 100% !important;
        max-width: 200px;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .smaller-devices-section-challenge {
    .smaller-devices-container {
      margin-left: -15px;
      padding-left: 15px;
      padding-bottom: 16px !important;
    }
  }
}

@media screen and (max-width: 1025px) {
  .preview-milestone-card {
    .milestone-card-descriptors {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}

.window-scroll {
  padding-bottom: 14px;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.search-mail {
  color: #5d6877;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -1px;
  }

  span {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-word;

    @media screen and (max-width: 1025px) {
      font-size: 12px;
    }
  }
}

.calendar-image {
  svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.search-filter-radio-button {
  display: flex;
  align-items: center;

  input {
    width: 14px !important;
    height: 14px !important;
    border-color: #c8cee1 !important;
    margin-top: 2px !important;
  }

  &.radioButton .radio:checked {
    background-color: #1d337c !important;
    box-shadow: 0 0 0 3px white, 0 0 0 2px white inset !important;
  }

  .radio:checked + span {
    color: #1d337c;
    font-weight: 600;
  }

  span {
    color: #5d6877;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px !important;
  }
}

.search-section-search-bar {
  input {
    padding-left: 16px !important;
    border-radius: 6px !important;

    &::placeholder {
      color: #666 !important;
    }
  }
}

.search-modal-mobile {
  @apply p-0 w-full fixed top-0 h-screen overflow-auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  min-height: 100vh;
  overflow-x: hidden;

  .global-search-mobile-view {
    width: 100%;

    .search {
      display: flex !important;
      border-radius: 6px;
      border: 1px solid #d3e0e9;
      background: #fff;
      box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.08);
      height: 48px;

      .searchIcon {
        position: absolute;
        left: 16px !important;
      }

      input {
        [dir="ltr"] & {
          padding-left: 40px;
          padding-right: 20px;
        }

        [dir="rtl"] & {
          padding-left: 20px;
          padding-right: 40px;
        }
      }
    }
  }

  .mobile-show-more-results {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -16px;
      height: 16px;
      width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 11L10 8L7 5' stroke='%231D337C' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }
}

.search-filter-footer {
  box-shadow: 0px 0px 6px 0px rgba(29, 51, 124, 0.25);
}

.search-filter-mobile {
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-section-search-bar {
  .searchIcon {
    position: absolute !important;
  }
}

@media screen and (max-width: 1025px) {
  .miniSidebar .sidebar.sidebar-mobile {
    display: none !important;
  }

  .miniSidebar::before {
    content: unset !important;
  }

  .mobile-sidebar-open .mobile-view-sidebar::before {
    content: "" !important;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 92;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0 0 0 / 0.6);
  }

  .mobile-sidebar-open .miniSidebar .sidebar.sidebar-mobile {
    display: flex !important;
  }
}

.mobile-sidebar-open {
  .sidebar {
    @apply w-20 justify-center;
    min-width: 80px;
    padding-left: 0;
    padding-right: 0;

    .text {
      @apply whitespace-nowrap hidden;
    }

    li {
      @apply justify-center items-center;
      min-height: 26px;

      @media (max-width: 1024px) {
        @apply items-start;
      }

      & + li {
        margin-top: 32px !important;

        @media (max-width: 1550px) {
          margin-top: 28px !important;
        }
      }
    }

    .logo {
      @apply pr-0;
      margin-bottom: 74px;
    }

    .navItem {
      .arrow {
        @apply hidden;

        @media (max-width: 1024px) {
          @apply block;
        }
      }
    }

    .subMenu {
      @apply hidden;

      @media (max-width: 1024px) {
        @apply block;
      }
    }

    .actionButtons {
      li {
        @apply flex flex-col justify-center w-full;

        button {
          @apply w-auto mx-auto;
        }

        div {
          @apply w-auto mx-auto;
        }
      }
    }

    .collapse {
      @apply hidden;
    }

    .collapsed {
      @apply block;
    }
  }

  .mainContainer {
    // @apply duration-500  transition-all ease-in-out;
    // transition: all 1s;
    [dir="ltr"] & {
      @apply ml-20;
    }

    [dir="rtl"] & {
      @apply mr-20;
    }

    @media (max-width: 1025px) {
      .mainContainer {
        // @apply duration-500  transition-all ease-in-out;
        // transition: all 1s;
        [dir="ltr"] & {
          @apply ml-10;
        }

        [dir="rtl"] & {
          @apply mr-0;
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .sidebar {
      overflow: hidden;
    }

    .mainContainer {
      margin-left: 0 !important;
    }

    .sidebar {
      @apply bg-primary-medium;
      display: flex !important;
      width: 90% !important;
      min-width: 90% !important;
      z-index: 93 !important;

      .menuIcon {
        @apply hidden;
      }

      .navItem {
        @apply w-full px-4;
      }

      .text {
        @apply block ltr:mr-auto rtl:ml-auto;
      }
    }
  }

  .logo {
    img {
      display: none;

      & + img {
        display: block;
      }
    }
  }

  .navbar {
    @apply w-full;

    @media (min-width: 1024px) {
      width: calc(100vw - 80px);
    }
  }

  .collapsed-side-bar {
    padding: 0 !important;
  }
}

.window-scroll-challenge {
  position: fixed;
  top: 34px;
  width: 100%;
  background-color: #ffffff;
  margin-left: -16px;
  padding-left: 16px;
  z-index: 91;
}

.searchOpenModal,
.alertOpenModal {
  .window-scroll-challenge {
    z-index: 0 !important;
  }
}

@media (max-width: 1024px) {
  .navigator-mobile-view {
    .timeLine {
      border-bottom: 1px solid #c2d2dd;
      margin-left: 0 !important;

      p {
        &:first-child {
          margin: 0 !important;
        }

        &.selected {
          &:after {
            display: none !important;
          }
        }

        &:after {
          display: none !important;
        }

        border-bottom: 0px solid #c2d2dd;
        font-weight: 400 !important;
        color: #5d6877 !important;

        &.active {
          &:after {
            display: block !important;
          }
        }
      }
    }
  }

  .challengeNav {
    .navigator-mobile-view .timeLine p.selected:after {
      display: none !important;
    }

    .timeLine p.active {
      &::before {
        content: "";
        @apply absolute inline-block h-1 w-full bg-secondary-medium bottom-0 top-auto;
        background-image: none !important;
      }
    }
  }

  .challengeNav {
    &.navigator-mobile-view .timeLine p.selected:after {
      display: none !important;
    }

    .timeLine p.active {
      &::before {
        content: "";
        @apply absolute inline-block h-1 w-full bg-secondary-medium bottom-0 top-auto;
        background-image: none !important;
      }
    }
  }
}

.msgListing {
  [class*="__outerdiv"] {
    @apply w-full;
  }
}

.targetIndustry {
  label {
    padding-bottom: 8px !important;
    line-height: 1rem !important;
  }

  [class*="__control"] {
    height: 48px !important;
  }
}

.tableFilterDropdown {
  opacity: 0 !important;
  visibility: hidden !important;
}

.filterActive {
  @apply text-primary-medium;

  & + .tableFilterDropdown {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.chipSecondary {
  @apply bg-primary-500 text-primary-medium;

  span {
    @apply text-primary-medium;
  }

  button {
    @apply text-primary-medium;

    SVG {
      path {
        @apply stroke-primary-medium;
      }
    }
  }
}

.researchArea {
  [class*="__control"] {
    background: white !important;
    padding-left: 16px !important;
    height: 38px !important;
  }

  [dir="rtl"] & {
    [class*="__control"] {
      padding-left: 0px !important;
      padding-right: 16px !important;
    }
  }

  [dir="ltr"] & {
    [class*="__value-container"] {
      padding-left: 0px !important;
      padding-right: 8px !important;
    }
  }

  [dir="rtl"] & {
    [class*="__value-container"] {
      padding-left: 8px !important;
      padding-right: 0px !important;
    }
  }

  [class*="__placeholder"] {
    font-size: 14px !important;
    color: #666 !important;
    font-weight: 400 !important;
  }

  [class*="__option"] {
    @apply truncate;
  }

  [class*="__menu"] {
    top: auto !important;
    bottom: 100% !important;
  }

  [class*="__indicators"] {
    @apply hidden;
  }
}

.truncateMSG {
  max-width: calc(100vw - 930px);

  @media (max-width: 1025px) {
    max-width: calc(100vw - 120px);
  }
}

.miniSidebar {
  .truncateMSG {
    max-width: calc(100vw - 750px);

    @media (max-width: 1025px) {
      max-width: calc(100vw - 120px);
    }
  }
}

.dirLTR {
  [dir="rtl"] & {
    direction: ltr;
    @apply text-right;
  }
}

.dirRTL_LTR {
  [dir="rtl"] & {
    direction: ltr;
    @apply text-right;
  }
}

@media (max-width: 1025px) {
  .viewIcon {
    padding: 0;
    width: 38px;

    span {
      @apply hidden;
    }
  }
}

[class*="-badge-liveText"] {
  span {
    color: #219c8d !important;
  }
}

[class*="-badge-openText"] {
  span {
    color: #5394de !important;
  }
}

[class*="-badge-draftText"] {
  span {
    color: #8e7846 !important;
  }
}

[class*="-badge-closedText"] {
  span {
    color: #f0534c !important;
  }
}

[class*="-badge-disabledText"] {
  span {
    color: #4e606d !important;
  }
}

.challengeCard {
  [data-tippy-root] {
    margin-top: -8px !important;
  }
}

.skeleton-loader {
  width: auto;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.msgDetailHide {
  .msgDetails {
    @apply hidden;
  }
}

.tippy-arrow {
  background: transparent !important;
}

.msgSend {
  input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 1024px) {
  .profileRightNav {
    .challengeNav {
      .timeLine p.selected {
        &::before {
          content: "";
          @apply absolute inline-block h-1 w-full bg-secondary-medium bottom-0 top-auto;
          background-image: none !important;
        }
      }
    }
  }
}

// Checkbox
.customCheckboxV2 {
  @apply flex items-center;

  input {
    @apply rounded-sm border border-primary-light bg-center;
    width: 18px;
    height: 18px;
    transform: none !important;
    transition: none !important;
    animation: none !important;
    border-radius: 4px;
    overflow: hidden;

    &:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");

      .rtl &,
      [dir="rtl"] & {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");
      }

      & + label {
        color: #1d337c;
        font-weight: 600;
      }
    }
  }

  label {
    @apply ltr:ml-2.5 rtl:mr-2.5;
    font-size: 13px;
    color: #5d6877;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.customCheckboxV3 {
  @apply flex items-center;

  input {
    @apply rounded-sm border border-primary-light bg-center;
    width: 18px;
    height: 18px;
    transform: none !important;
    transition: none !important;
    animation: none !important;
    border-radius: 4px;
    overflow: hidden;

    &:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");

      .rtl &,
      [dir="rtl"] & {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");
      }

      & + label {
        color: #1d337c;
        font-weight: 600;
      }
    }
  }

  label {
    @apply ltr:ml-2.5 rtl:mr-2.5 text-black text-sm;
    font-size: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

#to-top-button {
  svg {
    width: 26px;
    height: 26px;
  }
}

.eye-button {
  width: 38px;
  height: 38px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-white text-black shadow-100 hover:bg-secondary-medium hover:text-white rounded;
}

.app-c-domain_name {
  label {
    position: absolute;
    top: -18px;
    left: 100px;

    [dir="rtl"] & {
      left: 0;
      right: 45px;
    }
  }

  .form-control {
    @apply pt-2;
  }

  .info {
    @apply ltr:pl-3 rtl:pr-3 -mt-1 z-10 relative;
  }
}

.accessDenied {
  svg {
    width: 250px;
  }
}

.nominated-scholars-table {
  table {
    th,
    td {
      &:last-child {
        text-align: center;
      }
    }
  }
}

.filterRadioButtons {
  span {
    @apply text-black text-sm;
  }

  .radio:checked + span,
  .radio[aria-checked="true"] + span {
    color: #1d337c !important;
    font-weight: 600;
  }
}

.tableChip {
  display: flex !important;
  align-items: center;
  height: 20px;

  span {
    line-height: normal !important;
    padding: 0 !important;
  }
}

.researchArea {
  .customSelect__menu-list {
    max-height: 182px !important;
  }
}

.disabled-badge {
  @apply bg-gray-103 text-black opacity-40;
}

.editor-font-size,
.editor-font-family {
  border: none;
  color: black;
  box-shadow: none !important;
  outline: none !important;
}

.app-c-advisor {
  .info {
    @apply mb-0;
  }

  label {
    @apply ltr:pl-2.5 rtl:pr-2.5;
  }

  .checkbox {
    border-color: rgba(255, 255, 255, 0.5);
  }

  @apply flex items-center;

  input {
    @apply rounded-sm border border-primary-light bg-center bg-white;
    width: 22px;
    height: 22px;
    transform: none !important;
    transition: none !important;
    animation: none !important;
    border-radius: 4px;
    overflow: hidden;

    &:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");

      .rtl &,
      [dir="rtl"] & {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z' fill='%231D337C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7612 8.37157C17.2661 8.87089 17.2706 9.68495 16.7713 10.1898L10.8372 16.1898C10.5957 16.434 10.2665 16.5714 9.92308 16.5714C9.57963 16.5714 9.25045 16.434 9.00894 16.1898L6.37157 13.5231C5.87225 13.0183 5.87674 12.2042 6.38161 11.7049C6.88648 11.2056 7.70054 11.2101 8.19986 11.7149L9.92308 13.4573L14.943 8.38161C15.4423 7.87674 16.2564 7.87225 16.7612 8.37157Z' fill='white'/%3e%3c/svg%3e ");
      }
    }
  }
}

.bg-gradient-blueDark {
  input[disabled],
  textarea[disabled] {
    border-color: rgba(255, 255, 255, 0.02);
    background-color: hsla(0, 0%, 19%, 0.2);
  }

  input[disabled],
  textarea[disabled] {
    color: #cbcccc !important;
  }
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;

  [dir="rtl"] & {
    margin-right: 1.5em;
    margin-left: 0;
  }
}

.dirRTL-LTR {
  [dir="rtl"] & {
    direction: ltr;
  }
}
.mobile-sticky-header.stickyHeader .smaller-devices-container {
  @media screen and (max-width: 1024px) {
    display: block !important;
  }
}

.editor-font-family,
.editor-font-size {
  .rdw-dropdown-carettoclose {
    [dir="rtl"] & {
      left: 10%;
      right: unset;
    }
  }
}

.profile-view-modal-email-section {
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
  }
}

.challenge-list-item-target-industry {
  @media screen and (max-width: 768px) {
    display: block !important;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
