@layer components {
  .login-image {
    @apply w-full h-screen;
    background: url(../images/login-bgv2.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// PasswordMust
.passwordMust {
  div {
    @apply ltr:pl-6 rtl:pr-6;
  }

  input {
    @apply hidden;
  }
}

.password-must-icons::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='%23ECEFF0'/%3E%3C/svg%3E%0A");
  background-position: bottom;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-bottom: 2px;
  @apply ltr:-ml-6 ltr:mr-2 rtl:-mr-6 rtl:ml-2;
}

.password-success {
  @apply text-green-400;

  &::before {
    height: 17px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8.5' r='8' fill='%2327AE60'/%3E%3Cpath d='M4.53369 8.85672L6.82921 11.1522L11.1333 6.84814' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.password-fail {
  @apply text-red-200;

  &::before {
    height: 17px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8.5' r='8' fill='%23D8232A'/%3E%3Cpath d='M10.6663 5.8335L5.33301 11.1668' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.33301 5.8335L10.6663 11.1668' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.yourPasswordMust {
  button {
    @apply hidden;
  }
}

// Custom Select
.customSelect {
  &__control.customSelect__control {
    @apply h-12 cursor-pointer rounded border-primary-light text-sm text-black shadow-none outline-0;

    [class*="mobilePanel"] & {
      @apply text-sm;
    }

    &:hover {
      @apply border-primary-light text-black;
    }

    &--is-disabled {
      border-color: hsla(0, 0%, 0%, 0.05) !important;
      background-color: hsla(0, 0%, 0%, 0.05) !important;
    }
  }

  &__single-value {
    @apply font-medium text-base;
  }

  &__value-container {
    [dir="ltr"] & {
      padding-left: 16px !important;
      padding-right: 8px !important;
    }

    [dir="rtl"] & {
      padding-left: 8px !important;
      padding-right: 16px !important;
    }
  }

  &__placeholder {
    @apply text-sm font-normal text-gray-1100;
    color: #666666 !important;
    font-size: 16px !important;
    margin-left: 0 !important;
  }

  &__input-container {
    @apply font-medium;
  }

  &__indicator {
    [dir="ltr"] & {
      padding: 8px 8px 8px 0 !important;
    }

    [dir="rtl"] & {
      padding: 8px 0px 8px 8px !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg {
      @apply h-4 w-4 fill-primary-medium;
    }
  }

  &__menu {
    z-index: 10 !important;
    margin-top: 2px !important;
    border-radius: 4px !important;
    overflow: hidden !important;

    &-list {
      max-height: 215px !important;
    }
  }

  &__option.customSelect__option {
    @apply bg-white text-sm font-medium text-primary-medium border-b border-gray-400 last:border-none;
    height: 42px;
    cursor: pointer;

    @apply block items-center justify-between;

    &--is-focused.customSelect__option--is-focused {
      @apply bg-primary-light text-white;
    }

    &--is-selected.customSelect__option--is-selected {
      @apply bg-secondary-medium text-white border-b border-gray-400/10;

      img {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }

      p {
        color: white;
      }
    }
  }
}

.customSelectError {
  &__control.customSelectError__control {
    @apply h-12 cursor-pointer rounded border-red-100 text-sm text-black shadow-none outline-0;

    [class*="mobilePanel"] & {
      @apply text-sm;
    }

    &:hover {
      @apply border-red-100 text-black;
    }
  }

  &__single-value {
    @apply font-medium text-base;
  }

  &__placeholder {
    @apply text-sm font-normal text-gray-1100;
    color: #666666 !important;
    font-size: 16px !important;
    margin-left: 0 !important;
  }

  &__input-container {
    @apply font-medium;
  }

  &__value-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg {
      @apply h-4 w-4 fill-primary-medium;
    }
  }

  &__menu {
    z-index: 10 !important;
    border-radius: 0 !important;
    margin-top: 0 !important;

    &-list {
      max-height: 215px !important;
    }
  }

  &__option.customSelectError__option {
    @apply bg-white text-sm font-medium text-primary-medium border-b border-gray-400;
    height: 42px;
    cursor: pointer;

    @apply block items-center justify-between;

    &--is-focused.customSelectError__option--is-focused {
      @apply bg-primary-light text-white;
    }

    &--is-selected.customSelectError__option--is-selected {
      @apply bg-primary-medium text-white border-b border-gray-400/10;

      img {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }

      p {
        color: white;
      }
    }
  }
}

// Search Icon
.searchIcon {
  svg {
    @apply w-full;
  }
}

.language-btn {
  button {
    @apply bg-white;
  }

  .deActive {
    @apply bg-white;

    svg {
      @apply opacity-30;
    }
  }

  .active {
    @apply bg-gray-400;

    svg {
      @apply opacity-100;
    }
  }
}

// Header
.nav-header {
  .language-btn {
    @apply border-primary-100;

    button {
      @apply bg-primary-medium;

      & + button {
        [dir="ltr"] & {
          border-left-color: #223b8e !important;
        }

        [dir="rtl"] & {
          border-right-color: #223b8e !important;
        }
      }
    }

    .deActive {
      @apply bg-primary-medium;

      svg {
        @apply opacity-30;
      }
    }

    .active {
      @apply bg-primary-100;

      svg {
        @apply opacity-100;
      }
    }
  }
}

// custom toaster
.custom-toaster {
  min-width: 450px;

  @media (max-width: 640px) {
    min-width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
    left: 0 !important;
    right: 0 !important;
    top: 1rem !important;
  }

  .Toastify {
    &__toast {
      @apply border-none p-3 py-4 min-h-fit items-center ltr:pr-5 rtl:pl-5 shadow-200;
      border-radius: 10px !important;

      &--error {
        @apply bg-red-light;
        border: 1px solid #ebd0c8 !important;
      }

      &--success {
        border: 1px solid #d5eada !important;
        background: #f1f9f4 !important;
      }

      &--info {
        border: 1px solid #c3cbe4 !important;
        background: #e4e9f8 !important;
      }
    }

    &__toast-body {
      @apply text-sm text-gray-107 p-0;
    }

    &__close-button {
      @apply opacity-100 w-5 h-5 cursor-pointer self-center rtl:-order-1;

      &::before {
        content: "";
        display: block;
        @apply block w-5 h-5;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.0008 13.4037L7.10078 18.3037C6.91745 18.487 6.68411 18.5787 6.40078 18.5787C6.11745 18.5787 5.88411 18.487 5.70078 18.3037C5.51745 18.1204 5.42578 17.887 5.42578 17.6037C5.42578 17.3204 5.51745 17.087 5.70078 16.9037L10.6008 12.0037L5.70078 7.10371C5.51745 6.92038 5.42578 6.68704 5.42578 6.40371C5.42578 6.12038 5.51745 5.88704 5.70078 5.70371C5.88411 5.52038 6.11745 5.42871 6.40078 5.42871C6.68411 5.42871 6.91745 5.52038 7.10078 5.70371L12.0008 10.6037L16.9008 5.70371C17.0841 5.52038 17.3174 5.42871 17.6008 5.42871C17.8841 5.42871 18.1174 5.52038 18.3008 5.70371C18.4841 5.88704 18.5758 6.12038 18.5758 6.40371C18.5758 6.68704 18.4841 6.92038 18.3008 7.10371L13.4008 12.0037L18.3008 16.9037C18.4841 17.087 18.5758 17.3204 18.5758 17.6037C18.5758 17.887 18.4841 18.1204 18.3008 18.3037C18.1174 18.487 17.8841 18.5787 17.6008 18.5787C17.3174 18.5787 17.0841 18.487 16.9008 18.3037L12.0008 13.4037Z' fill='%23072438'/%3e%3c/svg%3e ");
        background-size: cover;
      }

      svg {
        @apply hidden;
      }
    }

    &__toast-icon {
      @apply w-9 mr-0 rtl:order-1;

      svg {
        @apply w-full;
      }

      & + div {
        @apply text-sm text-gray-107 w-full md:line-clamp-3 leading-normal px-3 ltr:pr-3 rtl:pl-3 rtl:text-right;
        max-width: 450px;
      }
    }
  }
}

.sidebar {
  .text {
    @apply whitespace-nowrap;
  }
}

.miniSidebar {
  .sidebar {
    @apply w-20 justify-center;
    min-width: 80px;
    padding-left: 0;
    padding-right: 0;

    .text {
      @apply whitespace-nowrap hidden;
    }

    li {
      @apply justify-center items-center;
      min-height: 26px;

      @media (max-width: 1024px) {
        @apply items-start;
      }

      & + li {
        margin-top: 32px !important;

        @media (max-width: 1550px) {
          margin-top: 28px !important;
        }
      }
    }

    .logo {
      @apply pr-0;
      margin-bottom: 74px;

      @media (max-width: 1550px) {
        margin-bottom: 40px;
      }
    }

    .navItem {
      @apply p-0 flex items-center justify-center rounded-lg;

      .arrow {
        @apply hidden;

        @media (max-width: 1024px) {
          @apply block;
        }
      }
    }

    .subMenu {
      @apply hidden;

      @media (max-width: 1024px) {
        @apply block;
      }
    }

    .actionButtons {
      li {
        @apply flex flex-col justify-center w-full;

        button {
          @apply w-auto mx-auto;
        }

        div {
          @apply w-auto mx-auto;
        }
      }
    }

    .collapse {
      @apply hidden;
    }

    .collapsed {
      @apply block;
    }
  }

  .mainContainer {
    // @apply duration-500  transition-all ease-in-out;
    // transition: all 1s;
    [dir="ltr"] & {
      @apply ml-20;
    }

    [dir="rtl"] & {
      @apply mr-20;
    }

    @media (max-width: 1025px) {
      [dir="ltr"] & {
        margin-left: 0 !important;
      }

      [dir="rtl"] & {
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 1025px) {
    .sidebar {
      overflow: hidden;
    }

    .mainContainer {
      margin-left: 0 !important;
    }

    .sidebar {
      @apply bg-primary-medium;
      display: flex !important;
      width: 90% !important;
      min-width: 90% !important;

      .menuIcon {
        @apply hidden;
      }

      .navItem {
        @apply w-full px-4;
      }

      .text {
        @apply block ltr:mr-auto rtl:ml-auto;
      }
    }
  }

  .logo {
    img {
      display: none;

      & + img {
        display: block;
      }
    }
  }

  .navbar {
    @apply w-full;

    @media (min-width: 1024px) {
      width: calc(100vw - 80px);
    }
  }

  .collapsed-side-bar {
    padding: 0 !important;
  }

  .logoutBtn {
    min-width: 42px;
    padding: 10px !important;
    height: 42px;
  }
}

// Modal
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}

.multipleSelect {
  &__control.multipleSelect__control {
    @apply h-12 cursor-pointer rounded-sm border-primary-light text-sm text-black shadow-none outline-0;

    [class*="mobilePanel"] & {
      @apply text-sm;
    }

    &:hover {
      @apply border-primary-light text-black;
    }

    &--is-disabled {
      border-color: hsla(0, 0%, 0%, 0.05) !important;
      background-color: hsla(0, 0%, 0%, 0.05) !important;
    }
  }

  &__single-value {
    @apply font-medium text-base;
  }

  &__placeholder {
    @apply text-sm font-normal text-gray-300;
    color: #a9b0b5 !important;
    font-size: 12px !important;
  }

  &__input-container {
    @apply font-medium;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg {
      @apply h-4 w-4 fill-primary-medium;
    }
  }

  &__menu {
    z-index: 10 !important;
    border-radius: 0 !important;
    margin-top: 0 !important;

    &-list {
      max-height: 215px !important;
    }
  }

  &__option.multipleSelect__option {
    @apply bg-white text-sm font-medium text-primary-medium border-b border-gray-400;
    height: 42px;
    cursor: pointer;

    @apply block items-center justify-between;

    &--is-focused.multipleSelect__option--is-focused {
      @apply bg-primary-light text-white;
    }

    &--is-selected.multipleSelect__option--is-selected {
      @apply bg-primary-medium text-white border-b border-gray-400/10;

      img {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }

      p {
        color: white;
      }
    }
  }

  &__indicators {
    display: none !important;
  }

  &__value-container {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: left 18px center;
    padding: 0 0 0 40px !important;
  }
}

.passwordMust {
  @apply space-y-2;
}

.removeIcon {
  svg {
    @apply hidden;
  }
}

.addProfilePhoto {
  @apply p-0;

  .uploadContainer {
    @apply bg-transparent p-0 mb-0;
  }

  span {
    @apply hidden;

    & + div {
      width: 92px;
      height: 92px;
    }
  }

  input {
    border-radius: 50%;
  }
}

.subtitle {
  @apply justify-start;

  .info {
    @apply block;

    .rtl &,
    [dir="rtl"] & {
      @apply ml-0;
    }
  }
}

// Navigation
.navigate {
  .active {
    @apply font-semibold text-primary-medium;

    &:after {
      content: "";
      @apply inline-block bg-primary-medium rounded-full absolute right-3;
      top: 6px;
      width: 9px;
      height: 9px;

      @media (max-width: 1024px) {
        position: absolute;
        bottom: -5px;
        top: auto;
        width: 100%;
        height: 2px;
        display: block !important;
        left: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .sidebar {
    @apply overflow-hidden pl-0 pr-0 min-w-0;
    box-shadow: 0 0 2rem 0 hsla(212, 17%, 60%, 0.15);
  }

  .miniSidebar {
    &:before {
      content: "";
      @apply w-screen h-screen bg-black/60 absolute right-0 top-0 z-40;
    }
  }
}

.loaderModal {
  width: 100px !important;
  height: 100px !important;
  overflow: hidden;
  background: white !important;
  @apply flex items-center justify-center;

  div {
    width: 60px !important;
    height: 60px !important;
    left: 0;
    top: 0;
    background: white !important;
    position: relative !important;
  }
}

.selectedCard {
  min-height: 120px;

  .tile-disabled {
    @apply opacity-50 pointer-events-none;
  }

  .pending,
  .active,
  .disabled,
  .active-research,
  .active-organizations,
  .active-institutes,
  .active-funding,
  .invited {
    @apply flex justify-center;

    &:after {
      @apply h-full rounded-lg absolute;
      content: "";
      width: 80%;
      bottom: -23px;
      filter: blur(17px);
    }
  }

  .pending,
  .active-research {
    @apply border-primary-medium rounded-md;

    &:after {
      background: rgba(29, 51, 124, 0.41);
    }
  }

  .active,
  .active-organizations {
    @apply border-green-100 rounded-md;

    &:after {
      background: rgba(14, 98, 49, 0.29);
    }
  }

  .disabled,
  .active-institutes {
    @apply border-orangeYellow rounded-md;

    &:after {
      background: rgba(154, 124, 36, 0.31);
    }
  }

  .active-funding,
  .invited {
    @apply border-purpleBlue rounded-md;

    &:after {
      background: rgba(114, 75, 171, 0.31);
    }
  }
}

.tooltipList {
  li {
    &:first-child {
      display: flex !important;
      margin-left: -14px;
    }
  }
}

.adminDetails {
  & + div {
    display: none;
  }
}

// NAVIGATE tree USER DETAILS
.timeLine {
  p {
    @apply relative text-gray-200 font-normal text-sm;

    &::before {
      content: "";
      @apply absolute inline-block bg-no-repeat z-20 left-0;
      height: 14px;
      width: 14px;
      top: 3px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='%23C8CEE1' /%3E%3Ccircle cx='7' cy='7' r='3.5' fill='white' fill-opacity='1' /%3E%3C/svg%3E%0A");

      .rtl &,
      [dir="rtl"] & {
        @apply left-auto right-0;
      }
    }

    &:after {
      content: "";
      @apply absolute h-11 z-10;
      left: 7px;
      background: #c8cee1;
      width: 1px;
      top: 10px;

      .rtl &,
      [dir="rtl"] & {
        @apply left-auto;
        right: 7px;
      }
    }

    &:last-child {
      &::after {
        @apply hidden;
      }
    }

    &.selected {
      @apply text-primary-medium font-semibold;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6.5' fill='%231D337C' stroke='%231D337C'/%3E%3Cpath d='M4.5 7.44444L6.03846 9L9.5 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    &.selected.active {
      @apply text-primary-medium font-semibold;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6.5' fill='%231D337C' stroke='%231D337C'/%3E%3Cpath d='M4.5 7.44444L6.03846 9L9.5 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &:after {
        content: "";
        @apply absolute h-11 z-10;
        left: 7px;
        background: #c8cee1;
        width: 1px;
        top: 10px;

        .rtl &,
        [dir="rtl"] & {
          @apply left-auto;
          right: 7px;
        }
      }
    }

    &.completed {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6.5' fill='%23C8CEE1' stroke='%23C8CEE1'/%3E%3Cpath d='M4.5 7.44444L6.03846 9L9.5 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  @media (max-width: 1024px) {
    @apply flex overflow-x-auto whitespace-nowrap w-[calc(100%+220px)] -ml-4;

    &::after {
      content: "";
      @apply hidden;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: #e8f1f6 !important;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid #e8f1f6;
    }

    p {
      border-bottom: 1px solid #c2d2dd;

      &:first-child {
        @apply ltr:ml-4 rtl:mr-4;
      }

      &:last-child {
        @apply ltr:mr-4 rtl:ml-4;
      }

      &:after,
      &::before {
        @apply hidden;
      }

      &.selected {
        &:after {
          @apply h-1 w-full bg-secondary-medium block top-auto left-0;
          bottom: -1px;
        }
      }

      &:after {
        content: "";
        @apply absolute h-11 z-10;
        left: 7px;
        background: #c8cee1;
        width: 1px;
        top: 10px;

        .rtl &,
        [dir="rtl"] & {
          @apply left-auto;
          right: 0;
        }
      }
    }
  }
}

.stickyHeader {
  .search,
  .notificationIcon {
    border-color: rgb(211, 224, 233) !important;

    @media (max-width: 1024px) {
      border-color: #253e8f !important;
    }
  }
}

.multiSelect {
  &__control.multiSelect__control {
    @apply h-12 cursor-pointer rounded border-primary-light text-sm ltr:pl-11 rtl:pr-11 text-black shadow-none outline-0;

    [class*="mobilePanel"] & {
      @apply text-sm;
    }

    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;

    [dir="ltr"] & {
      background-position: left 16px center;
    }

    [dir="rtl"] & {
      background-position: right 16px center;
    }

    &:hover {
      @apply border-primary-light text-black;
    }

    &--is-disabled {
      border-color: hsla(0, 0%, 0%, 0.05) !important;
      background-color: hsla(0, 0%, 0%, 0.05) !important;
    }
  }

  &__value-container {
    padding: 0 !important;
  }

  &__single-value {
    @apply font-medium text-base;
  }

  &__placeholder {
    @apply text-sm lg:text-base;
    color: #666666;
  }

  &__input-container {
    @apply font-medium;
  }

  &__clear-indicator {
  }

  &__dropdown-indicator {
    display: none !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    svg {
      @apply h-4 w-4 fill-primary-medium;
    }
  }

  &__menu {
    z-index: 10 !important;
    border-radius: 0 !important;
    margin-top: 0 !important;

    &-list {
      max-height: 215px !important;
    }
  }

  &__option.multiSelect__option {
    @apply bg-white text-sm font-medium text-primary-medium border-b border-gray-400 truncate;
    height: 42px;
    cursor: pointer;

    @apply block items-center justify-between;

    &--is-focused.multiSelect__option--is-focused {
      @apply bg-primary-light text-white;
    }

    &--is-selected.multiSelect__option--is-selected {
      @apply bg-primary-medium text-white border-b border-gray-400/10;

      img {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }

      p {
        color: white;
      }
    }
  }
}

// Login

.default-login-image {
  background-image: url(../images/defaultLogin.png);
  @apply bg-fixed bg-cover bg-no-repeat;
}

.default-inputs-section {
  @apply relative;

  &::after {
    content: "";
    background-image: url("../images/defaultInputsBg.png");
    background-size: 100% 100%;
    @apply ltr:right-0 ltr:left-[-185px] rtl:left-0 rtl:right-[-185px] rtl:scale-x-[-1] max-[1280px]:ltr:left-[-150px] max-[1280px]:rtl:right-[-150px] max-[1024px]:hidden absolute top-0 w-full h-full bg-no-repeat;
  }

  &::before {
    content: "";
    bottom: -1px;
    background-image: url("../images/loginGraphics.svg");
    height: 249px;
    background-repeat: no-repeat;
    @apply ltr:left-[-520px] rtl:right-[-520px] rtl:scale-x-[-1] max-[1280px]:ltr:left-[-440px] max-[1280px]:rtl:right-[-440px] max-[1024px]:hidden absolute w-full bg-no-repeat;
  }

  .defaultInput,
  .side-bar-mobile {
    [dir="rtl"] & {
      direction: rtl;
      text-align: right;
    }
  }
}

.default-primary-button {
  @apply flex items-center justify-center bg-secondary-medium rounded-md h-[58px] text-white text-base font-semibold py-[17px] hover:bg-green-300;
}

.default-secondary-button {
  @apply flex items-center justify-center rounded-md py-4 border border-secondary-medium text-base font-semibold text-secondary-medium hover:bg-white;
  height: 58px;
}

.language-selector {
  .customLangSelect__control {
    @apply bg-primary-1000 rounded-md border-0 h-full;
    box-shadow: 0px 10px 14px 0px rgba(1, 2, 6, 0.11);

    .customLangSelect__single-value {
      @apply text-white text-base;
    }

    .customLangSelect__dropdown-indicator svg {
      fill: #ffffff;
    }
  }

  .customLangSelect__value-container {
    @apply p-0;
  }

  .customLangSelect__placeholder {
    color: #ffffff !important;
    font-size: 16px !important;
    padding-left: 16px !important;
  }

  .customLangSelect__menu-list {
    @apply p-0;
  }

  .customLangSelect__input {
    @apply hidden;
  }
}

.country-code-selector {
  .customSelect {
    &__menu {
      min-width: 300px;
      margin-top: 1px !important;
      border-radius: 4px !important;
    }
  }
}

// Custom Select
.customLangSelect {
  &__control.customLangSelect__control {
    @apply cursor-pointer rounded border-primary-light text-sm text-black shadow-200 outline-0;
    height: 38px;

    [class*="mobilePanel"] & {
      @apply text-sm;
    }

    &:hover {
      @apply border-primary-light text-black;
    }

    &--is-disabled {
      border-color: hsla(0, 0%, 0%, 0.05) !important;
      background-color: hsla(0, 0%, 0%, 0.05) !important;
    }
  }

  &__single-value {
    @apply font-medium ltr:pl-4 ltr:pr-8 rtl:pl-8 rtl:pr-4 ltr:text-left rtl:text-right text-base;
    margin: 0 !important;
  }

  &__placeholder {
    @apply text-sm font-normal text-gray-300;
    color: #a9b0b5 !important;
    font-size: 12px !important;
  }

  &__input-container {
    @apply font-medium;
    margin: 0 !important;
    padding: 0 !important;
  }

  &__indicators {
    @apply justify-end absolute w-full;
    padding: 0 !important;

    div {
      @apply w-full cursor-pointer p-0 ltr:pr-2 rtl:pl-2 items-center;
      height: 38px;

      svg {
        @apply ltr:ml-auto rtl:mr-auto;
      }
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg {
      @apply h-4 w-4 fill-primary-medium;
    }
  }

  &__menu {
    z-index: 10 !important;
    border-radius: 0 !important;
    margin-top: 0 !important;

    &-list {
      max-height: 215px !important;
    }
  }

  &__option.customLangSelect__option {
    @apply bg-white text-sm font-medium text-primary-medium border-t border-gray-400/30;
    cursor: pointer;
    @apply block items-center justify-between;

    &--is-focused.customLangSelect__option--is-focused {
      @apply bg-primary-light text-white;
    }

    &--is-selected.customLangSelect__option--is-selected {
      @apply bg-primary-medium text-white border-b border-gray-400/10;

      img {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }

      p {
        color: white;
      }
    }
  }
}

.countryCode {
  .customSelect__menu-list {
    max-height: 140px !important;
  }
}

.docPreview {
  iframe,
  video,
  img,
  audio {
    @apply w-full p-5 max-h-[calc(100vh-220px)];
    min-height: 250px;
  }
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #1d337c;
  color: #1d337c;
}

body.react-confirm-alert-body-element {
  overflow: hidden !important;
}

@media (max-width: 768px) {
  .react-confirm-alert-overlay {
    align-items: flex-end !important;
  }
}

.searchMobile {
  input {
    @apply px-2;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.coverImageChallenges {
  position: relative;
}

.coverImageChallenges:hover::after {
  transform: scale(1.2);
}

.rdw-editor-wrapper {
  @apply overflow-hidden rounded-md;

  .public-DraftStyleDefault-block {
    margin: 0em 0;
  }
}

.rdw-justify-aligned-block > div,
.rdw-left-aligned-block > div,
.rdw-right-aligned-block > div,
.rdw-center-aligned-block > div {
  display: contents;
}

.wrapper-class {
  min-height: 300px;
}

.wrapper-class-hide-placeholder {
  min-height: 300px;

  .public-DraftEditorPlaceholder-inner,
  .public-DraftEditorPlaceholder-hasFocus {
    @apply hidden;
  }
}

@media (max-width: 1024px) {
  .nav-header,
  .animateNone {
    animation: none !important;
  }
}

.WB_BW {
  word-break: break-word !important;
}

.preview-navigator-line {
  ul {
    position: relative;

    &::before {
      @apply absolute inline-block;
      height: calc(100% - 14px);
      width: 1px;
      content: "";
      background: #c8cee1;
      left: -20px;
      top: 3px;

      [dir="rtl"] & {
        left: auto;
        right: -20px;
      }
    }
  }
}

.preview-navigator-line {
  ul {
    position: relative;

    &::before {
      @apply absolute inline-block;
      height: calc(100% - 14px);
      width: 1px;
      content: "";
      background: #c8cee1;
      left: -20px;
      top: 3px;

      [dir="rtl"] & {
        left: auto;
        right: -20px;
      }
    }
  }
}

.preview-navigator-circle {
  @apply relative font-semibold text-gradient-blueDark;
}

.preview-navigator-circle::before {
  @apply absolute inline-block;
  content: "";
  height: 14px;
  width: 14px;
  inset-inline-start: -27px;
  inset-block-start: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='%231D337C'/%3E%3Cpath d='M4.5 7.44444L6.03846 9L9.5 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.preview-navigator-circle-unchecked {
  @apply relative text-gray-200;
}

.preview-navigator-circle-unchecked::before {
  @apply absolute inline-block;
  content: "";
  inset-inline-start: -27px;
  height: 14px;
  width: 14px;
  inset-block-start: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6.5' fill='%23C8CEE1' stroke='%23C8CEE1'/%3E%3Cpath d='M4.5 7.44444L6.03846 9L9.5 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.preview-milestones-circle {
  @apply relative;
}

.preview-milestones-circle::before {
  @apply absolute inline-block;
  width: 14px;
  height: 14px;
  content: "";
  inset-block-start: 0;
  inset-inline-start: -25px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='%23D1ECFE'/%3E%3Ccircle cx='7' cy='7' r='3' fill='white'/%3E%3C/svg%3E%0A");
}

.preview-milestones-line {
  @apply relative;
}

.preview-milestones-line::before {
  @apply absolute inline-block h-full w-1;
  content: "";
  inset-block-start: 0;
  inset-inline-start: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='501' viewBox='0 0 4 501' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='501' fill='url(%23paint0_linear_1996_38968)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1996_38968' x1='2' y1='0' x2='2' y2='501' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.877994' stop-color='%23D1ECFE'/%3E%3Cstop offset='1' stop-color='white'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: cover;
}

.challengeInfo {
  &.sticky {
    @apply fixed -mt-7 ltr:-ml-10 rtl:-mr-10 z-40 p-0 ltr:pl-5 rtl:pr-5 ltr:pr-10 rtl:pl-10 pb-4;
    width: calc(100vw - 250px);

    .imageHolder {
      @apply hidden;
    }

    .dataHolder {
      @apply flex items-center space-x-4 rtl:space-x-reverse;

      .dataWrapper {
        @apply pt-0 space-y-0 space-x-4 rtl:space-x-reverse flex-row;

        .sessionTwo {
          @apply space-x-4 rtl:space-x-reverse relative px-4 ltr:pl-0 rtl:pr-0;
          margin-left: 0 !important;

          &:after,
          &:before {
            @apply bg-gray-200 absolute;
            content: "";
            width: 1px;
            height: 13px;
            top: 4px;
          }

          &:after {
            @apply left-0 hidden;
          }

          &:before {
            @apply right-0 hidden;
          }

          .date {
            @apply relative ltr:pr-4 rtl:pl-4;

            &:before {
              @apply bg-gray-200 absolute ltr:right-0 rtl:left-0;
              content: "";
              width: 1px;
              height: 13px;
              top: 4px;
            }
          }
        }

        .sessionOne,
        .sessionThree {
          @apply hidden;
        }

        .showMore {
          @apply flex;
        }
      }
    }
  }
}

.selectedPreviewTab {
  @apply font-semibold text-gray-108 relative;

  &::after {
    content: "";
    @apply absolute inline-block h-1 w-full bg-secondary-medium z-[100];
    inset-block-start: 31px;
    inset-inline-start: 0;
  }
}

.current-status {
  @apply border-2 border-secondary-medium;
}

.noPost {
  .noData {
    margin-bottom: auto !important;
    height: 100% !important;
    @apply flex items-center justify-center;
  }
}

.IconTypeButton {
  button {
    margin: 0 !important;
  }
}

.activeCard {
  @apply border-secondary-medium;
}

.tabContainer {
  @apply border-b border-primary-102/20;

  div {
    @apply border-b border-primary-102/20 lg:border-none h-full;
  }

  @media (max-width: 768px) {
    @apply flex overflow-x-auto overflow-y-hidden whitespace-nowrap w-[calc(100%-16px)] -ml-4 -mr-4 relative;

    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: #e8f1f6 !important;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid #e8f1f6;
    }

    div {
      &:first-child {
        @apply ltr:ml-0 rtl:mr-0;
      }

      &:last-child {
        @apply ltr:mr-0 rtl:ml-0;
      }
    }
  }
}

.challenge-slider-container, .case-studies-slider-container {
  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: #e8f1f6 !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 0 solid #e8f1f6;
  }
}

.activeMsg,
.activeForum {
  @apply bg-gray-light rounded-md;
}

.miniSidebar .headerMsg {
  width: calc(100% - 430px) !important;
}

.headerMsg {
  width: calc(100% - 600px) !important;
}

.msgSend {
  label {
    @apply hidden;
  }
}

.researchScholarProfile {
  div {
    width: 120px;
    height: 120px;

    @media (max-width: 1024px) {
      width: 60px;
      height: 60px;
    }
  }
}

.formDetails {
  .chatReplayHolder {
    @apply overflow-y-auto overflow-x-hidden pr-1 rtl:pl-1;
    max-height: calc(100vh - 160px);

    .chatPostHolder {
      // [class*="__outerdiv"] {
      //   @apply max-h-[calc(100vh-250px)] overflow-y-auto overflow-x-hidden pr-1;
      // }
    }

    .chatReplay {
      @apply w-full;
    }

    .nodata {
      height: calc(100vh - 165px);

      @media (max-width: 1024px) {
        height: calc(100vh - 200px);
      }
    }
  }

  .msgStatus {
    & + .chatReplayHolder {
      max-height: calc(100vh - 250px);

      @media (max-width: 1024px) {
        max-height: calc(100vh - 287px);
      }
    }
  }
}

.truncate span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate a span {
  overflow: initial;
}

.attachmentBtn {
  span {
    @apply hidden;
  }
}

.attachmentBtn[aria-disabled="true"] {
  @apply opacity-50 pointer-events-none;
}

.chipOpen {
  span {
    @apply text-gray-107;
  }
}

.startUp + button {
  @apply ml-1.5;
}

.tableChip {
  span {
    @apply p-0 text-center w-full;
  }
}

.msgHeader {
  .smaller-devices-container {
    @apply hidden;
  }
}

@media (max-width: 1024px) {
  .msgDetails {
    @apply fixed top-0 left-0 bg-gray-light block;

    .headerMsg {
      width: 100% !important;
    }

    .headerMsg .sessionOne {
      @apply hidden;
    }

    .formDetails {
      @apply px-4;
    }

    .buttonHolder {
      @apply fixed left-0 bottom-0 bg-white w-full p-4 shadow-600 z-40;

      button {
        @apply w-full;
      }
    }

    .chatReplayHolder .chatPostHolder {
      max-height: 100%;
    }
  }
}

.chatField.required {
  @apply border-red-200;
}

.editorText {
  p,
  span,
  div {
    font-size: 14px !important;
    word-break: break-word;
    white-space: normal;
  }
}

.tableFilterDropdown {
  min-width: 286px;

  .searchFiled {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17 17L13.889 13.8889M16.1111 8.55556C16.1111 12.7284 12.7284 16.1111 8.55556 16.1111C4.38274 16.1111 1 12.7284 1 8.55556C1 4.38274 4.38274 1 8.55556 1C12.7284 1 16.1111 4.38274 16.1111 8.55556Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center left 13px;
  }

  .checkbox {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #7890a1 !important;
  }

  .checkbox:checked,
  .checkbox[checked="true"],
  .checkbox[aria-checked="true"] {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='1.5' fill='%231D337C' stroke='%231D337C'/%3e%3cpath d='M15 4.5L6.75 12.75L3 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ") !important;
    border: none !important;
  }

  .react-datepicker {
    @apply w-full;

    .react-datepicker__month-container {
      @apply w-full;
    }
  }

  label {
    @apply truncate;
  }
}

.card-slider-content {
  p,
  span {
    @apply line-clamp-3 text-skin-textMutedDark;
    font-size: 15px;
  }
}

.datePickerCustome {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    @apply rounded-full overflow-hidden;
  }
}

.notificationAlert {
  .tabContainer {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;

    @media (max-width: 1024px) {
      margin: 0 16px !important;
    }

    div + div {
      margin-left: 28px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 28px;
      }

      @media (max-width: 1024px) {
        margin-left: 24px;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 24px;
        }
      }
    }
  }

  .tab {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 0 8px 0 !important;
  }
}

.customTable {
  th {
    &:first-child {
      .tableFilterDropdown {
        left: -14px;
      }
    }
  }

  [dir="rtl"] & {
    th {
      &:first-child {
        .tableFilterDropdown {
          left: -250px;
        }
      }
    }
  }
}

.miniSidebar {
  .challengeInfo.sticky {
    width: calc(100vw - 80px);
  }
}

.sendBtn {
  @media (max-width: 1024px) {
    max-width: 48px;
    padding: 10px;

    span {
      @apply hidden;
    }
  }
}

.msgScreen {
  .backBtn {
    @media (max-width: 1024px) {
      display: flex;
    }
  }
}

@media (max-width: 1024px) {
  .tabMobileView {
    @apply -mr-4;
    width: calc(100% + 16px);

    .tab {
      @apply text-gray-108 font-normal text-sm px-3 relative;

      &.active {
        @apply font-semibold;

        &:after {
          content: "";
          @apply absolute w-full h-1 bg-secondary-medium;
          bottom: -2px;
        }
      }
    }

    &.tab-mobile-view {
      margin-right: 0 !important;
      width: 100% !important;
    }
  }
}

// .innerScroll {
//   max-height: calc(100vh - 330px);
//   overflow: auto;
// }
@media (max-width: 1024px) {
  .searchDetails {
    .global-search-mobile-view {
      width: 100%;

      .search {
        display: flex !important;
        border-radius: 6px;
        border: 1px solid #d3e0e9;
        background: #fff;
        box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.08);
        height: 48px;

        .searchIcon {
          position: absolute;
          left: 16px !important;
        }

        input {
          [dir="ltr"] & {
            padding-left: 40px;
            padding-right: 20px;
          }

          [dir="rtl"] & {
            padding-left: 20px;
            padding-right: 40px;
          }
        }
      }
    }

    .mobile-show-more-results {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: -16px;
        height: 16px;
        width: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 11L10 8L7 5' stroke='%231D337C' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }

    .searchDetailsSearch {
      @apply fixed top-0 w-full bg-white;
    }

    .searchDetailsList {
      padding-top: 74px;
    }

    .searchListing {
      height: 100vh;
    }
  }
}

.editorText {
  // p {
  //   display: inherit;
  //   strong {
  //     display: block;
  //   }
  // }
  button {
    margin-left: 0 !important;
  }
}

.rotate__180 {
  [dir="rtl"] & {
    svg {
      rotate: 180deg !important;
    }
  }
}

.rotate_180 {
  [dir="rtl"] & {
    svg {
      rotate: 180deg !important;
    }
  }
}

.titleTextTruncate {
  div {
    @apply truncate;
  }

  [dir="rtl"] & {
    direction: ltr;
    text-align: right;
  }
}

.default-inputs-section {
  .errorField {
    background: #f5d5d5 !important;
  }

  .customSelectError__control {
    background: #f5d5d5 !important;
  }
}

.makeAllRead {
  @apply whitespace-nowrap;
}

.tabContainer {
  & + .makeAllRead {
    @apply border-b border-primary-102/20 whitespace-nowrap mr-4 lg:mr-0 -ml-4 lg:ml-0;
    padding-bottom: 7px;
  }
}

.profile_picture {
  div {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    background: transparent !important;
    border: none !important;
  }
}

.collaborationFilter {
  ul {
    width: 180px;
  }
}

@media (max-width: 1024px) {
  .homePage {
    .tabContainer div {
      border: none !important;
    }
  }

  .collaborationSelect {
    & + div {
      height: calc(100vh - 188px) !important;
    }
  }

  .menuSidebar {
    max-height: calc(100vh - 500px);
  }

  .stickyHeader {
    .smaller-devices-container {
      display: none !important;
    }
  }
}

.rdw-dropdown-carettoopen {
  [dir="rtl"] & {
    right: auto;
    left: 10%;
  }
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  [dir="rtl"] & {
    left: auto;
    right: -36px;
    text-align: left;
  }
}

.public-DraftStyleDefault-listLTR {
  [dir="rtl"] & {
    direction: rtl;
  }
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  [dir="rtl"] & {
    margin-right: 1.5em;
    margin-left: 0;
  }
}
