@layer base {
  :root {
    --color-primary: #1d337c;
    --color-primary01: #e5eaed;
    --color-primary02: #ecf0ff;
    --color-primary03: #e8f1f4;
    --color-primary04: #dcdfe0;
    --color-primaryLight: #e5f1ff;
    --color-primaryExtraLight: #2e4697;
    --color-primaryMedium: #2f53c8;
    --color-primaryDark: #1d2f6d;
    --color-textSecondary: #00e3ad;
    --color-textSecondary03: #00e3ad;
    --color-secondaryLight: #cbfff3;
    --color-secondaryExtraLight: #3564ff;
    --color-secondaryMedium: #079271;
    --color-secondaryDark: #0ad2a3;
    --color-textBase: #03041c;
    --color-textBaseLight: #272d37;
    --color-textMuted: #4e5562;
    --color-textMutedMedium: #b67a06;
    --color-textMutedDark: #5d5e77;
    --color-textMutedLight: #5f6d7e;
    --color-textPrimary: #1b3899;
    --color-textSecondary: #00af86;
    --color-textSecondary01: #37d3ae;
    --color-textSecondary02: #00e8b1;
    --color-infoLight: #fdf4c4;
    --color-infoMedium: #fdf4c4;
    --color-bgLight: #e8f1f4;
  }
}

/*header*/
.home-container {
  @apply mx-auto px-10;
  @media screen and (min-width: 1360px) {
    max-width: 1360px;
  }
  @media screen and (max-width: 767px) {
    @apply px-5;
  }
}
.news-events-container {
  @apply mx-auto px-10;
  @media screen and (min-width: 1360px) {
    max-width: 1380px;
  }
  @media screen and (max-width: 767px) {
    @apply px-5;
  }
}
.case-studies-slider-container {
  @apply mx-auto px-10;
  @media screen and (min-width: 1360px) {
    max-width: 1380px;
  }
  @media screen and (max-width: 1024px) {
    @apply pr-0;
  }
  @media screen and (max-width: 767px) {
    @apply pl-5 pr-0;
  }
}
.challenge-slider-container {
  @apply mx-auto px-10;
  @media screen and (min-width: 1360px) {
    max-width: 1360px;
  }
  @media screen and (max-width: 1024px) {
    @apply pr-0;
  }
  @media screen and (max-width: 767px) {
    @apply pl-5 pr-0;
  }
}
.home-page-language-button {
  .customLangSelect__control {
    @apply bg-white border-none shadow-none;
    input {
      @apply caret-white;
    }
    .customLangSelect__single-value {
      @apply text-skin-primary pr-6;
      @media screen and (max-width: 767px) {
        font-size: 13px;
        @apply ltr:pl-2 rtl:pl-6 rtl:pr-2;
      }
    }
  }
  .customLangSelect__control--menu-is-open {
    background-color: #f4f4f4;
    input {
      caret-color: #f4f4f4;
    }
  }
}

.home-page-search-bar {
  @apply shadow-none;
  height: 46px;
  input {
    @apply border border-gray-900 rounded-md bg-white;
    padding-left: 15px;
    font-size: 15px;
    line-height: 24px;
    &::placeholder {
      @apply text-skin-textBase font-normal opacity-50;
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.home-navigation-items {
  li {
    span {
      @apply font-medium leading-6 text-skin-textMuted whitespace-nowrap cursor-pointer;
      font-size: 15px;
      @media screen and (max-width: 768px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
    &:hover span {
      @apply text-skin-primary;
    }
    &.active span {
      @apply text-skin-primary font-semibold;
    }
  }
}

.home-about-us-slider {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
  .slick-dots {
    @apply absolute top-0 ltr:right-0 rtl:left-0 h-full flex items-center ltr:justify-end rtl:justify-start;
    width: 376px;
    border-radius: 0 !important;
    background: transparent !important;
    z-index: 6;
    &::before {
      // content: " ";
      opacity: 0.699999988079071;
      // background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 57.49%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      @apply ltr:right-0 rtl:left-0 rtl:-scale-x-100;
    }
    ul {
      display: flex;
      flex-direction: column;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .pagination-main-container {
          display: flex;
          align-items: center;
          p,
          span {
            color: #fff;
            text-align: right;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            border: none;
            opacity: 0.4000000059604645;
          }
        }
        @apply before:rtl:w-16 after:ltr:w-16;
        &::after {
          content: "";
          // width: 64px;
          border: 1px solid #ffffff;
          height: 1px;
          margin-left: 4px;
          opacity: 0;
        }
        &::before {
          content: "";
          // width: 64px;
          border: 1px solid #ffffff;
          height: 1px;
          margin-right: 4px;
          opacity: 0;
        }
      }
      .slick-active {
        .pagination-main-container {
          p,
          span {
            color: #fff;
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            opacity: 1;
            transition: all 0.5s;
          }
        }
        @apply before:rtl:opacity-100 after:ltr:opacity-100;
      }
    }
    @media screen and (max-width: 1023px) {
      @apply relative w-full;
      justify-content: center !important;
      background: #ffffff !important;
      height: 35px;
      padding-top: 14px;
      padding-bottom: 14px;
      ul {
        flex-direction: row;
        justify-content: center;
      }
      li {
        margin: 0 !important;
        &::before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
      .pagination-main-container {
        width: 7px !important;
        height: 7px !important;
        border-radius: 50%;
        background-color: #d9e2f4;
        gap: 8px;
        margin: 0 4px !important;

        .number-pagination,
        span {
          display: none;
        }
      }
      .slick-active .pagination-main-container {
        @apply bg-skin-textPrimary;
      }
      &::before {
        display: none;
      }
    }
  }
  .slide-content {
    &::after {
      // content: "";
      height: 100%;
      width: 100%;
      opacity: 0.699999988079071;
      // background: linear-gradient(
      //   360deg,
      //   #000 0%,
      //   rgba(0, 0, 0, 0) 64.58%,
      //   rgba(0, 0, 0, 0) 94.79%
      // );
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    h1 {
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      letter-spacing: -2px;
    }
  }
  .slick-slide {
    float: left !important;
  }
  .slide-content-inner-wrapper {
    @apply absolute bottom-0  z-10 ltr:left-0 rtl:right-0;
  }
  // .banner-slider-image {
  //   @media screen and (min-height: 650px) and (max-height: 790px) {
  //     min-height: 700px;
  //   }
  // }
  @media screen and (max-width: 1023px) {
    .slick-list {
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .slick-slide {
      &::before {
        // content: " ";
        opacity: 0.699999988079071;
        // background: linear-gradient(
        //   270deg,
        //   #000 0%,
        //   rgba(0, 0, 0, 0) 57.49%,
        //   rgba(0, 0, 0, 0) 100%
        // );
        height: 100%;
        position: absolute;
        top: 0;
        width: 376px;
        z-index: 2;
        @apply ltr:right-0 rtl:left-0 rtl:-scale-x-100;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .slick-slide {
      &::before {
        display: none;
      }
    }
  }
}
.learn-more-button {
  span {
    @apply ltr:mr-2 rtl:ml-2 rtl:mr-0;
  }
  svg {
    @apply rtl:-order-1 rtl:rotate-180;
  }
}

.news-events-slider-section {
  display: grid !important;
  grid-template-columns: 298px 1fr 298px;
  column-gap: 30px;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 250px 1fr 250px;
    column-gap: 20px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 200px 1fr 200px;
    column-gap: 10px;
  }
  .news-events-slider-2 {
    &::before {
      content: "";
      opacity: 0.800000011920929;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .info-section {
    .badge-type {
      background: rgba(7, 219, 169, 0.2);
    }
  }
}
.slider-buttons {
  position: absolute;
  top: -76px;
  // right: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  @apply ltr:right-0 rtl:left-0;
  button:disabled,
  button[disabled] {
    background-color: rgba(29, 47, 109, 0.1) !important;
  }
  .previous-button {
    @apply rtl:order-1;
  }
}

.slider-card-main-container {
  box-shadow: 0px 2px 2px 0px rgba(28, 32, 44, 0.15);
}
.case-studies-slider {
  .slider-card-main-container {
    box-shadow: 0px 2px 2px 0px rgba(28, 32, 44, 0.15);
  }
  .card-slider-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  // .card-slider-content {
  //   overflow: hidden;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 3;
  //   -webkit-box-orient: vertical;
  // }
  .slick-arrow {
    display: none !important;
  }
}
.home-login-button {
  svg {
    display: none;
  }
  svg + span {
    padding-left: 0 !important;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    margin-left: 4px;
    svg {
      display: block;
      width: 14px;
      height: 15px;
    }
    span {
      display: none;
    }
  }
}
.news-events-heading,
.news-events-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news-and-events-slider {
  margin-left: -10px;
  margin-right: -40px;
  .slider-buttons {
    top: -81px;
    @apply ltr:right-10 rtl:left-2;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    margin-left: -8px;
    margin-right: -20px;
  }
}
.sign-up-button,
.learn-more-button {
  @media screen and (max-width: 400px) {
    min-width: 130px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.banner-slider {
  @media screen and (min-width: 1024px) {
    max-width: 1440px !important;
    min-height: 700px !important;
    height: 836px !important;
    margin-left: auto;
    margin-right: auto;
    .banner-slider-image {
      min-height: 100% !important;
      height: 100% !important;
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1023px) {
    min-height: 542px !important;
    height: 542px !important;
    .banner-slider-image {
      min-height: 100% !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: 666px !important;
    height: 666px !important;
    .banner-slider-image {
      min-height: 100% !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
.banner-slider-description {
  @media screen and (max-width: 400px) {
    width: 300px !important;
  }
}
@media screen and (min-width: 1440px) {
  .menuHolder {
    border-bottom: 0 !important;
  }
  .homeStickyHeader {
    .menuHolder {
      border-bottom: 1px solid #e5eaed !important;
    }
  }
  .banner-slider {
    border-radius: 0 !important;
    .slick-list {
      border-radius: 12px;
    }
  }
}
.homeStickyHeader {
  .menuHolder {
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
  }
}

// SKS

.joinWrapper {
  background: url(../images/home-images/joinGraphics.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @apply py-5 md:py-10;
  .joinUs {
    span {
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    }
  }
}
// Tab style
.tabHomeContainer {
  .tab {
    @apply text-skin-textMuted font-normal px-0 text-base relative leading-[26px] h-auto whitespace-nowrap;
    &.active {
      @apply font-semibold text-xl leading-7 text-skin-primary;
      &:after {
        content: "";
        @apply absolute w-full h-1 bg-secondary-medium;
        bottom: -18px;
      }
    }
  }
  .challengeContent {
    svg {
      @apply mt-0.5;
    }
  }
}

// Accordion style
.homeAccordion {
  .accordion-item {
    transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
  .accordion-title.active {
    @apply font-semibold;
    & + .accordion-content {
      max-height: max-content;
    }
  }
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    @apply transition-all;
    transition: max-height 0.5s ease;
    svg {
      @apply -mt-0.5 md:mt-0.5 w-5 md:w-6;
    }
  }
}

// ContactUs
.contactUsHolder {
  .form-control {
    label {
      @apply hidden;
    }
    input {
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    }
  }
  textarea {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  }
  button {
    span {
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    }
  }
}
// Footer
.footerContainer {
  ul {
    li {
      span {
        @apply text-white cursor-pointer;
        &:hover {
          @apply text-white underline;
        }
      }
      &.active {
        a {
          @apply text-white font-normal;
        }
      }
    }
  }
}
.case-studies-slider {
  .slick-list {
    margin-left: -15px;
    margin-right: -15px;

    @media screen and (max-width: 1024px) {
      margin-left: -10px;
      margin-right: -10px;
    }
    @media screen and (max-width: 768px) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  .slider-buttons {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.homeStickyHeader {
  .logoHolder {
    @apply hidden;
  }
}
.menuHolder {
  ul {
    li {
      &:last-child {
        @media screen and (max-width: 1024px) {
          @apply ltr:pr-10 rtl:pl-10;
        }
        @media screen and (max-width: 768px) {
          @apply ltr:pr-5 rtl:pl-5;
        }
      }
    }
  }
}
.news-events-container {
  img {
    @apply object-cover;
  }
}
.menuHolder {
  ul {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0) !important;
    }
  }
}
